.subheader {
    border-radius: 2.5em 0 2.5em 0;
    background: rgb(96, 113, 178);
    color: white;
    width: fit-content;
    margin: auto;
    padding-left: 2.5em;
    padding-right: 2.5em;
}

.unique-features-heading {
    position: relative;
    overflow: hidden;
    background: #6172B3;
    transition: background 0.3s;
    display: inline-block;
    font-weight: bold;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 2.5em 0 2.5em 0;
    margin-top: 20px;
    font-size: 1.5em;
}

.container {
    max-width: 1200px;
    margin: auto;
  }
  
  .row.mt-5 {
    display: flex;
    flex-wrap: wrap; /* Enables wrapping to the next line */
    justify-content: center;
    gap: 20px; /* Adds spacing between items */
  }
  
  .col-4 {
    padding: 15px;
    flex: 1 1 300px; /* Allows items to take up space responsively with a minimum width */
    max-width: 100%;
  }
  
  
  h2 {
    text-align: center;
    font-weight: bold;
  }