.home-bubble {
  border-radius: 5em 5em 5em 0;
  color: white;
  background: linear-gradient(180deg, rgba(92, 45, 135, 1) 0%, rgba(31, 133, 193, 1) 100%);
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
  opacity: 0; /* Start fully transparent */
  transform: translateY(50px); /* Start slightly lower */
}

.home-bubble img {
  width: 50%;
  height: auto;
  margin-bottom: 0.5em; /* Reduced space below the image */
}

.unique-header {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-top: 0.5em; /* Adjusted top margin to control space above the text */
}

@keyframes riseUp {
  from {
      opacity: 0;
      transform: translateY(50px); /* Start slightly lower */
  }
  to {
      opacity: 1;
      transform: translateY(0); /* End in original position */
  }
}

.home-bubble.in-view {
  animation: riseUp 1s ease-out forwards; /* Trigger the animation when in-view */
  animation-delay: inherit; /* Respect delay from inline style */
}
