body {
  background: #dabeff 0%;
  background-attachment: fixed;
  background-size: cover;
  margin: 0;
  padding: 0;

  font-family: Arial, sans-serif;
}

/*edit h1*/
h1 {
  font-size: 4ch;
  color: #1f1968;
  text-align: center;
}
