.contact-us-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .contact-us {
    position: relative;
    overflow: hidden;
    background: linear-gradient(90deg, rgba(92, 45, 135, 1) 0%, rgba(31, 133, 193, 1) 100%);
    transition: background 0.3s;
    display: inline-block;
    font-weight: bold;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 2.5em 0 2.5em 0;
  }
  
  .contact-us:hover {
    background: linear-gradient(90deg, rgba(92, 45, 135, 0.8) 0%, rgba(31, 133, 193, 0.8) 100%);
  }
  
  .contact-us:active {
    background: linear-gradient(90deg, rgba(92, 45, 135, 1.2) 0%, rgba(31, 133, 193, 1.2) 100%);
  }
  
  .contact-us::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s, width 0.3s, height 0.3s;
  }
  
  .contact-us:active::after {
    width: 200%;
    height: 200%;
    opacity: 1;
    transition: opacity 0.3s, width 0.3s 0.3s, height 0.3s 0.3s;
  }
  