.gradient-section {
    position: relative;
    overflow: visible; /* Allow content to flow outside */
    background: linear-gradient(180deg, #dabeff 0%, #95CEF6 100%);
    padding: 10px;
    min-height: 100px;
}

  
.bubble-container {
    position: absolute;
    top: -20%; /* Adjust upward to overlap SplashSection */
    left: 0;
    width: 100%;
    height: 120%; /* Allow bubbles to animate beyond */
}

.bubble-container.blog-context {
    overflow: hidden;
}

.gradient-section.blog-context {
    overflow: hidden;
}
  
  .content {
    position: relative;
    z-index: 2; /* Higher z-index to place content above bubbles */
  }
  
  .bubble {
    position: absolute;
    bottom: -20%;
    width: 25px;
    height: 20px;
    background-size: cover;
    opacity: 0.7;
    animation: riseAndWobble 10s linear;
    z-index: 1;
  }
  
  @keyframes riseAndWobble {
    0% {
      bottom: -25%;
      transform: translateX(0);
      opacity: 0;
    }
    15% {
      bottom: 0%;
      transform: translateX(var(--drift-left));
      opacity: 0.2;
    }
    30% {
      bottom: 25%;
      transform: translateX(var(--drift-right));
    }
    45% {
      bottom: 50%;
      transform: translateX(calc(var(--drift-left) / 2));
      opacity: 0.9;
    }
    60% {
      bottom: 75%;
      transform: translateX(calc(var(--drift-right) / 2));
    }
    75% {
      bottom: 100%;
      transform: translateX(0);
      opacity: 0.2;
    }
    90% {
      bottom: 125%;
      transform: translateX(0);
      opacity: 0;
    }
    100% {
      bottom: 125%;
      transform: translateX(0);
      opacity: 0;
    }
  }
  