/* Blog.css */

.blog-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0px;
    background-color: #f9f9f93b;
    min-height: auto;
    overflow: hidden; /* Prevent the entire page from scrolling */
    border-radius: 16px; /* Adjust value for roundness */
}


.posts-section {
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto; /* Allow only the posts section to scroll */
    height: calc(100vh - 40px); /* Adjust height to fit within the viewport */
    padding-bottom: 60px; /* Ensure space for the bottom bar */
}

.post-box {
    background-color: #ffffffd0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.post-box h2 {
    margin: 0 0 10px;
    font-size: 1.5rem;
    color: #333333;
}

.post-box p {
    margin: 0 0 10px;
    font-size: 1rem;
    line-height: 1.6;
    color: #555555;
}

.post-date {
    font-size: 0.85rem;
    color: #e01b84;
}

/* Ensure sidebar takes up remaining space */
/* Sidebar styles */
.sidebar {
    flex: 1; /* Sidebar takes up 1/4 of space on larger screens */
    background: linear-gradient(180deg, rgba(31, 25, 104, 0) -50%, rgba(31, 25, 104, 1) 100%), 
                linear-gradient(180deg, rgba(31, 25, 104, 0) -50%, rgba(31, 25, 104, 0.45) 100%), 
                linear-gradient(180deg, rgba(31, 25, 104, 0) -50%, rgba(31, 25, 104, 0.45) 100%), 
                url("./images/tiled.png") !important;
    background-blend-mode: darken, multiply, multiply;
    color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    height: auto; /* Adjust height dynamically */
    overflow-y: auto;
    position: relative;
}

/* Hide sidebar on small screens */
@media (max-width: 600px) {
    .sidebar {
        display: none; /* Hides the sidebar */
    }

    .posts-section {
        flex: 1; /* Ensure posts section takes full width */
    }

    .blog-container {
        flex-direction: column; /* Stack content vertically */
    }
}


.sidebar h3 {
    margin: 0 0 15px;
    font-size: 1.25rem;
    color: #333333;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar li {
    margin: 10px 0;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s, color 0.2s;
    background-color: #f0f0f0;
    color: #000000;
    transition: transform 0.2s, box-shadow 0.2s;
}

.sidebar li:hover {
    background-color: #007bff;
    color: #ffffff;
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.sidebar li.active {
    background-color: #007bff;
    color: #ffffff;
    font-weight: bold;
}

.sidebar h2 {
    margin: 0 0 15px;
    font-size: 1.25rem;
    color: #ffffff;
    font-weight: bold;
}

/* Define the rounded class for rounded corners */
.rounded {
    border-radius: 8px;
}

/* Define the shadow class for a subtle shadow effect */
.shadow {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Center images horizontally */
.center {
    display: block;
    margin: 0 auto;
}

/* Limit image size while maintaining aspect ratio */
.limited-size {
    max-width: 100%;
    max-height: 300px;
    display: block;
    margin: 0 auto;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Caption styling */
.caption {
    font-size: 0.9rem;
    color: #555555;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 15px;
    font-style: italic;
}