.partners-section {
    position: relative;
    padding: 20px 0;
    background-color: #f8f9fa; /* Light background color to distinguish section */
    text-align: center;
    z-index: 3;
    overflow:auto
}

.partners-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px; /* Space between logos */
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.partner-logo img {
    max-height: 60px; /* Adjust height as needed */
    width: auto;
    transition: transform 0.2s ease; /* Smooth hover effect */
}

.partner-logo img:hover {
    transform: scale(1.05); /* Slightly increase size on hover */
}
