/* General header styles */
header {
  font-family: 'Poppins', sans-serif;
  background-color: #1f1968;
  display: flex;
  align-items: center;
  padding: 0 0px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Logo container */
.logo-container {
  margin-left: 0px;
  padding: 5px;
}

.logo {
  height: 60px; /* Adjust the size of the logo */
}

/* Navigation links */
header .nav {
  list-style: none;
  display: flex;
  margin-left: auto; /* Align the links to the right */
  margin-right: 20px;
}

header .nav-item {
  margin-left: 15px;
  font-size: clamp(0.5rem, 2.5vw, 1.2rem); /* Responsive font size */
}

header .nav-link {
  color: white !important;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  white-space: nowrap; /* Prevents the text from breaking into a new line */
}

header .nav-link:hover {
  transform: scale(1.05); /* Scales up the link on hover */
}


/* Active link styling */
header .nav-link.active {
  position: relative;
}


header .nav-link.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #6a1b9a, #15b7ff);
  position: absolute;
  bottom: -10px;
  left: 0;
}

/* Ensure text does not overflow and prevent new lines */
header .nav-link {
  white-space: nowrap; /* Prevents the text from breaking into a new line */
}

/* Remove white space above the header */
body, html {
  margin: 0;
  padding: 0;
}
